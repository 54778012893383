<template>
  <div class="pageborder">
    <div class="pageback">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="单局查询" />
      <div class="pagepadding">
        <el-button
          size="small"
          type="success"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="searchDialog = true">筛选设置</el-button>
        <el-button
          size="small"
          type="warning"
          style="margin-left: 16px;margin-bottom: 16px;margin-right: 20px;"
          @click="newDialog = true">新增/修改单局</el-button>
        <el-date-picker
          v-model="datevalue"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getList()"
          :picker-options="Global.pickerOptions">
        </el-date-picker>
        <div v-loading="loading">
          <el-table
            :data="tableData"
            :row-key="row => row.id"
            style="width: 100%">
            <el-table-column label="唯一ID" prop="id"> </el-table-column>
            <el-table-column label="开始时间">
              <template slot-scope="scope">
                {{Global.getDateNum(new Date(parseInt(scope.row.gametimestamp)))}}
              </template>
            </el-table-column>
            <el-table-column label="比赛唯一ID" prop="matchid"> </el-table-column>
            <!-- <el-table-column label="牌桌id" prop="tableid"> </el-table-column> -->
            <!-- <el-table-column label="桌子id" prop="tableid"> </el-table-column> -->
            <!-- <el-table-column label="备注" prop="tip"> </el-table-column> -->
            <el-table-column label="设置列表">
              <template slot-scope="scope">
                {{scope.row.options}}
              </template>
            </el-table-column>
            <el-table-column label="记录状态">
              <template slot-scope="scope">
                {{scope.row.ended == 1 ? '1(已记录)' : scope.row.ended == 2 ? '2(已删除)' : '0(未记录)'}}
              </template>
            </el-table-column>
            <el-table-column label="玩家列表">
              <template slot-scope="scope">
                {{scope.row.playerids}}
              </template>
            </el-table-column>
            <el-table-column label="玩家名称列表">
              <template slot-scope="scope">
                {{scope.row.playernames}}
              </template>
            </el-table-column>
            <el-table-column label="得分列表">
              <template slot-scope="scope">
                {{scope.row.scores}}
              </template>
            </el-table-column>
            <el-table-column label="顺位列表">
              <template slot-scope="scope">
                {{scope.row.ranks}}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="160">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="warning"
                  @click="deleteGame(scope.row.id, 1)">记录</el-button>
                <el-button
                  size="small"
                  type="danger"
                  @click="deleteGame(scope.row.id, 2)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 30px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="params.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="allamount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="搜索内容" :visible.sync="searchDialog">
      <div v-for="(search, index1) in searchNameList" :key="index1">
        <el-input placeholder="请输入内容" v-model="searchObj[search.label]" style="margin:5px;">
          <template slot="prepend">{{search.name}}</template>
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="searchDialog = false">取 消</el-button>
        <el-button type="primary" @click="search()">搜 索</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增/修改单局" :visible.sync="newDialog" v-loading="newLoading">
      <div v-for="(user, index2) in userNameList" :key="index2">
        <el-input placeholder="请输入内容" v-model="userInfoObj[user.label]" style="margin:5px;" 
          :show-password="(user.label == 'password' || user.label == 'confirmPassword') ? true : false">
          <template slot="prepend">{{user.name}}</template>
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newDialog = false">取 消</el-button>
        <el-button type="primary" @click="newUser()">新增/修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api';

export default {
  data() {
    return {
      params: {
        page: 1,
        pagesize: 10
      },
      tableData: [],
      allamount: 0,
      loading: false,
      searchObj: {},
      searchNameList: [],
      searchDialog: false,
      newLoading: false,
      userInfoObj: {},
      userNameList: [],
      newDialog: false,
      datevalue: [new Date(new Date(new Date().toLocaleDateString()).getTime()), new Date(new Date(new Date().toLocaleDateString()).getTime() + 86400000)]
    };
  },
  async mounted() {
    this.searchNameList = [];
    this.searchNameList.push({name: '比赛id',label: 'matchid'});
    this.searchNameList.push({name: '玩家id',label: 'playerid'});
    this.userNameList = [];
    this.userNameList.push({name: '唯一id，不填则新增',label: 'id'});
    this.userNameList.push({name: '设置列表',label: 'options'});
    this.userNameList.push({name: '比赛id',label: 'matchid'});
    this.userNameList.push({name: '备注',label: 'tip'});
    this.userNameList.push({name: '玩家数组',label: 'playerids'});
    this.userNameList.push({name: '得分数组',label: 'scores'});
    this.userNameList.push({name: '排名数组',label: 'ranks'});
    await this.getList();
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.params.pagesize = val;
      this.params.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.params.page = val;
      this.getList();
    },
    search(){
      this.searchDialog = false;
      this.params.page = 1;
      this.getList();
    },
    async getList(){
      try{
        this.loading = true;
        var postbody = {};
        if(this.params.page >= 1){
          postbody.offset = (this.params.page - 1) * this.params.pagesize;
          postbody.limit = this.params.pagesize;
        } 
        postbody.searchObj = this.searchObj;
        if(this.datevalue && this.datevalue[0] && this.datevalue[1]){
          postbody.searchObj.timerange = [this.datevalue[0].getTime(), this.datevalue[1].getTime()];
        }else{
          postbody.searchObj.timerange = undefined;
        }
        const { result } = await api.post('/searchGame', postbody);
        console.log(result);
        for(var i in result.rows){
          result.rows[i].playernames = result.gamename[i].playernames;
        }
        this.tableData = result.rows;
        this.allamount = result.count;
        this.loading = false;
      }catch(e){
        console.error(e);
        this.loading = false;
      }
    },
    async newUser(){
      try{
        this.newLoading = true;
        const result = await api.post('/addGame', this.userInfoObj);
        console.log(result);
        this.$message.success('新增/修改单局成功');
        this.userInfoObj = {};
        this.newDialog = false;
        this.newLoading = false;
        await this.getList();
      }catch(e){
        console.error(e);
        this.newLoading = false;
      }
    },
    async deleteGame(id, type){
      var message = '确认要删除吗？'
      if(type == 1){
        message = '确认要记录吗？'
      }
      const sure = await new Promise((resolve, reject) => {
        this.$msgbox({
          title: "提示",
          dangerouslyUseHTMLString: true,
          message: message,
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              done();
              resolve(true);
            } else {
              done();
              resolve(false);
            }
          }
        });
      });
      if (!sure) return;
      try{
        this.newLoading = true;
        const result = await api.post('/addGame', {
          id: id,
          ended: type
        });
        console.log(result);
        if(type == 1){
          this.$message.success('记录对局成功');
        }else{
          this.$message.success('删除对局成功');
        }
        this.userInfoObj = {};
        this.newDialog = false;
        this.newLoading = false;
        await this.getList();
      }catch(e){
        console.error(e);
        this.newLoading = false;
      }
    },
    datepicked(){
      this.getList();
    }
  },
};
</script>

<style scoped>
.pagepadding {
  padding: 20px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}
.overhide{
  width: 144px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
